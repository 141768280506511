import moment from 'moment';
const model = {
  modelName: 'shop_notice',
  label: '購物須知',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    title: {
      type: 'text',
      label: '標題',
      rules: 'required',
    },
    content: {
      type: 'editor',
      label: '內容',
      signed: false,
    },
    shop_notice_classes: {
      type: 'belongsToMany',
      label: '購物須知類別',
      modelName: 'shop_notice_class',
      labelInLocale: true,
    },
    description: {
      type: 'text',
      label: '說明',
    },
    publish_at: {
      type: 'datetime',
      label: '發布時間',
      defaultValue: moment().format('YYYY-MM-DD hh:00:00'),
    },
    cover_image: {
      type: 'pocket_image',
      label: '封面圖片 (建議500k以下)',
      signed: false,
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    tags: {
      type: 'tags',
      label: '標籤',
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
  },
};

export default model;
